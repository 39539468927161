.box{
    padding: 15px 10px 30px;
    border: 1px solid #eee;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .label{
        font-size: 0.85rem;
        font-weight: 600;
        // color:#777;
    }
    .value{
        font-size:2rem;
        font-weight: 600;

    }
}