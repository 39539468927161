.sidebar {
	height: calc(100vh - #{$navbar-height});
	background: $sidebar-light-bg;
	padding: 0;
	width: $sidebar-width-lg;
	z-index: 11;
	transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
	-webkit-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
	-moz-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
	-ms-transition: width $action-transition-duration $action-transition-timing-function,
		background $action-transition-duration $action-transition-timing-function;
	position: fixed;
	overflow: auto;
	.nav {
		overflow: hidden;
		flex-wrap: nowrap;
		flex-direction: column;

		&:not(.sub-menu) {
			padding-bottom: 60px;
		}

		.nav-item {
			padding: 0 $sidebar-menu-padding-x;
			@include transition-duration(0.25s);
			transition-property: background;
			-webkit-transition-property: background;

			.ReactCollapse--collapse {
				transition: height 500ms;
			}

			.collapse {
				z-index: 999;
			}

			.nav-link {
				@include display-flex;
				@include align-items(center);
				white-space: nowrap;
				padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
				color: $sidebar-light-menu-color;
				@include transition-duration(0.45s);
				transition-property: color;
				-webkit-transition-property: color;
				cursor: pointer;

				i {
					color: inherit;

					&.menu-icon {
						font-size: $sidebar-icon-font-size;
						line-height: 1;
						// margin-left: auto;
						margin-right: 0.5rem;

						.rtl & {
							margin-left: 0;
							// margin-right: auto;
						}

						color: $sidebar-light-menu-icon-color;

						&:before {
							vertical-align: middle;
						}
					}

					&.menu-arrow {
						font: normal normal normal 24px/1 "Material Design Icons";
						line-height: 1;
						font-size: $sidebar-icon-font-size;
						margin-left: auto;
						transition: transform 0.2s ease-in-out;

						.rtl & {
							margin-left: 0;
							margin-right: auto;
						}

						color: $sidebar-light-menu-arrow-color;

						&:before {
							content: "\f141";
							font-size: inherit;
							color: inherit;

							.rtl & {
								content: "\f142";
							}
						}

						+ .menu-icon {
							margin-left: 0.25rem;
							margin-bottom: 0.25rem;

							.rtl & {
								margin-left: 0;
								margin-right: 0.25rem;
							}
						}
					}
				}

				.menu-title {
					color: inherit;
					display: inline-block;
					font-size: $sidebar-menu-font-size;
					line-height: 1;
					vertical-align: middle;
				}

				.badge {
					margin-right: auto;
					margin-left: 1rem;
				}

				&.menu-expanded {
					.menu-arrow {
						transform: rotate(-90deg);
					}
				}
			}

			&.active {
				.nav-link {
					.menu-title {
						color: $sidebar-light-menu-active-color;
						font-weight: $font-weight-medium;
					}

					i {
						color: theme-color(primary);
					}
				}
			}

			&:hover {
				background: $sidebar-light-menu-hover-bg;
			}

			&.nav-profile {
				.nav-link {
					height: auto;
					line-height: 1;
					border-top: 0;
					padding: 1.25rem 0;

					.nav-profile-image {
						width: 44px;
						height: 44px;

						img {
							width: 44px;
							height: 44px;
							border-radius: 100%;
						}
					}

					.nav-profile-text {
						display: flex;
						flex-direction: column;
						margin-left: 1rem;

						.rtl & {
							margin-left: auto;
							margin-right: 1rem;
						}
					}

					.nav-profile-badge {
						font-size: 1.125rem;
						margin-left: auto;

						.rtl & {
							margin-left: 0;
							margin-right: auto;
						}
					}
				}
			}

			&.sidebar-actions {
				margin-top: 1rem;

				.nav-link {
					border-top: 0;
					display: block;
					height: auto;
				}

				&:hover {
					background: initial;

					.nav-link {
						color: initial;
					}
				}
			}
		}

		&:not(.sub-menu) {
			> .nav-item {
				&:hover {
					&:not(.nav-category):not(.nav-profile) {
						> .nav-link {
							color: $sidebar-light-menu-hover-color;
						}
					}
				}
			}
		}

		&.sub-menu {
			padding-bottom: 20px;
			margin-top: 0;
			list-style: none;

			.nav-item {
				padding: 0;

				.nav-link {
					color: $sidebar-light-submenu-color;
					padding: $sidebar-submenu-item-padding;
					position: relative;
					font-size: $sidebar-submenu-font-size;
					line-height: 1;
					height: auto;
					border-top: 0;

					&:before {
						content: "\F054";
						font-family: "Material Design Icons";
						display: block;
						position: absolute;
						left: 0px;

						.rtl & {
							left: auto;
							right: 0;
							content: "\F04D";
						}

						top: 50%;
						@include transform(translateY(-50%));
						color: lighten($sidebar-light-submenu-color, 10%);
						font-size: 0.75rem;
					}

					&.active {
						color: $sidebar-light-menu-active-color;
						background: transparent;
					}

					&:hover {
						color: $sidebar-light-submenu-hover-color;
					}
				}

				&:hover {
					background: transparent;
				}
			}
		}
	}
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
	.sidebar-offcanvas {
		position: fixed;
		max-height: calc(100vh - #{$navbar-height});
		top: $navbar-height;
		bottom: 0;
		overflow: auto;
		left: -$sidebar-width-lg;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;

		&.active {
			left: 0;
		}
	}
}
