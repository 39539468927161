.container {
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: flex;
	align-items: center;

	.content {
		background-color: rgb(16, 210, 16);
		width: 60px;
		height: 60px;
		border-radius: 100%;
		font-size: 30px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.messageBox {
		padding: 5px;
		display: flex;
		align-items: center;
		.triangle {
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-right: 0;
			border-left: 10px solid #f3f2f2;
		}
		.messageContent {
			padding: 10px;
			background-color: #f3f2f2;

			// border: 1px solid #eaeaea;
		}
	}
}
